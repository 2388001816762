import { useState, useEffect } from 'react';
import useBrowserMode from '../shared/hooks/useBrowserMode';

function useScreenSize(): number {
   const { window } = useBrowserMode()
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

export default useScreenSize;
