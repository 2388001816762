import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IStoriesProps } from '../stories';

function storiesAdapter(data: any): IStoriesProps {
    return {
        image: data.imagenPrincipal.file.url,
        title: data.tituloGeneral,
        description: documentToReactComponents(data.descripcion.json),
        btnTitle: data.tituloBoton,
        redirect: data.redireccionBoton,
        cardsList:
            data?.referenciaPlantillaContenido?.map((card) => ({
                image: card.imagenPrincipal[0].file.url,
                title: card.tituloBanner,
                description: documentToReactComponents(card.descripcion.json),
                bgColor: card.tituloDescripcion,
                textColor: card.tipoTitulo,
                redirect: card.redireccionBoton,
                btnTitle: card.tituloBoton,
            })) ?? [],
    };
}

export default storiesAdapter;
