import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styles from './cardsOverflow.module.scss';

export interface ICardsOverflowProps {
    cardsList: [
        {
            image: string;
            title: string;
            description: any;
            redirect: string;
            btnTitle: string;
        },
    ];
}

const CardsOverflow = ({ cardsList }: ICardsOverflowProps) => {
    return (
        <Grid container spacing={2} className={styles.o_cards_overflow}>
            {cardsList.map((card, key) => (
                <Grid
                    item
                    key={`grid-${key}`}
                    className={styles.o_cards_overflow__card}
                >
                    <CardContent className={styles.o_cards_overflow__img}>
                        <CardMedia
                            component="img"
                            width="100%"
                            image={card.image}
                            alt={card.title}
                        />
                    </CardContent>
                    <CardContent className={styles.o_cards_overflow__info}>
                        <Typography
                            component="div"
                            className={styles.o_cards_overflow__num}
                        >
                            0{key + 1}
                        </Typography>
                        <Typography
                            variant="h2"
                            component="div"
                            className={styles.o_cards_overflow__title}
                        >
                            {card.title}
                        </Typography>
                        <Typography
                            variant="body2"
                            className={styles.o_cards_overflow__desc}
                        >
                            {card.description}
                        </Typography>
                    </CardContent>
                    <CardActions className={styles.o_cards_overflow__actions}>
                        <Button
                            component="a"
                            size="small"
                            href={card.redirect}
                            className={styles.o_cards_overflow__btn}
                        >
                            {card.btnTitle}
                        </Button>
                    </CardActions>
                </Grid>
            ))}
        </Grid>
    );
};

export default CardsOverflow;
