import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ICardsOverflowProps } from '../cardsOverflow';

function cardsOverflowAdapter(data: any): ICardsOverflowProps {
    return {
        cardsList:
            data?.map((card) => ({
                image: card.imagenPrincipal[0].file.url,
                title: card.tituloBanner,
                description: documentToReactComponents(card.descripcion.json),
                redirect: card.redireccionBoton,
                btnTitle: card.tituloBoton
            })) ?? [],
    };
}

export default cardsOverflowAdapter;
