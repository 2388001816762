import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import YoutubePlayer from '../../molecules/youtube-player/youtubePlayer';
import styles from './cardYouTube.module.scss';
import Grow from '@material-ui/core/Grow';

export interface ICardYouTubeProps {
    title: string;
    video: string;
}

const CardYouTube = ({ title, video }: ICardYouTubeProps) => {
    return (
        <Card className={styles.o_cardyoutube}>
            <Grow in timeout={500} appear>
                <CardContent>
                    <Typography
                        variant="h2"
                        component="div"
                        dangerouslySetInnerHTML={{ __html: title }}
                        className={styles.o_cardyoutube__title}
                    />
                </CardContent>
            </Grow>
            <Grow in timeout={500} appear>
                <CardContent className={styles.o_cardyoutube__video}>
                    <YoutubePlayer youtubeId={video} />
                </CardContent>
            </Grow>
        </Card>
    );
};

export default CardYouTube;
