import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardMedia,
    Grid,
    Grow,
    Slide,
    Typography,
} from '@material-ui/core';
import styles from './cardsConocer.module.scss';

export interface ICardsConocerProps {
    title?: string;
    description?: any;
    cardsList: [
        {
            image: string;
            title: string;
            description: any;
            redirect: string;
            btnTitle: string;
        },
    ];
}

const cardsConocer = ({
    title,
    description,
    cardsList,
}: ICardsConocerProps) => {
    const [isVisible, setIsVisible] = useState(false);

    const elemRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (elemRef.current) {
            observer.observe(elemRef.current);
        }
        return () => observer.disconnect();
    }, []);

    return (
        <Box className={styles.o_cards_conocer}>
            <Grow in={isVisible} timeout={1500}>
                <Box className={styles.o_cards_conocer__intro}>
                    {title && (
                        <Typography
                            className={styles.o_cards_conocer__h2}
                            variant="h2"
                        >
                            {title}
                        </Typography>
                    )}
                    {description && (
                        <Typography
                            className={styles.o_cards_conocer__body}
                            variant="body1"
                        >
                            {description}
                        </Typography>
                    )}
                </Box>
            </Grow>
            <Slide direction="right" in={isVisible} timeout={1500}>
                <div ref={elemRef}>
                    {cardsList.map((card, key) => (
                        <Card
                            key={key}
                            className={styles.o_cards_conocer__card}
                        >
                            <Grid
                                container
                                spacing={2}
                                className={styles.o_cards_conocer__container}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={styles.o_cards_conocer__info}
                                >
                                    <div
                                        className={styles.o_cards_conocer__cta}
                                    >
                                        <Typography
                                            variant="h2"
                                            dangerouslySetInnerHTML={{
                                                __html: card.title,
                                            }}
                                            className={
                                                styles.o_cards_conocer__title
                                            }
                                        />
                                        <Typography
                                            variant="body1"
                                            className={
                                                styles.o_cards_conocer__desc
                                            }
                                        >
                                            {card.description}
                                        </Typography>
                                        {card.redirect && (
                                            <CardActions
                                                className={
                                                    styles.o_cards_conocer__actions
                                                }
                                            >
                                                <Button
                                                    variant="contained"
                                                    href={card.redirect}
                                                    className={
                                                        styles.o_cards_conocer__btn
                                                    }
                                                >
                                                    {card.btnTitle}
                                                </Button>
                                            </CardActions>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={card.image}
                                        alt={card.title}
                                        className={styles.o_cards_conocer__img}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    ))}
                </div>
            </Slide>
        </Box>
    );
};

export default cardsConocer;
