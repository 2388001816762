import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ICardsConocerProps } from '../cardsConocer';
import { customTitle } from '../../../../utils/customTitle';

function cardsConocerAdapter(data: any): ICardsConocerProps {
    return {
        title: data.tituloGeneral ? data.tituloGeneral : '',
        description: data.descripcion ? documentToReactComponents(data.descripcion.json) : '',
        cardsList:
            data?.referenciaPlantillaContenido?.map((card) => ({
                image: card.imagenPrincipal[0].file.url,
                title: customTitle(card.tituloBanner),
                description: documentToReactComponents(card.descripcion.json),
                redirect: card.redireccionBoton ? card.redireccionBoton : '',
                btnTitle: card.tituloBoton,
            })) ?? [],
    };
}

export default cardsConocerAdapter;
