import { customTitle } from '../../../../utils/customTitle';
import { ICardYouTubeProps } from '../cardYouTube';

function cardYouTubeAdapter(data: any): ICardYouTubeProps {
    return {
      title: customTitle(data.tituloGeneral),
      video: data.redireccionBoton
    };
}

export default cardYouTubeAdapter;
