export function customTitle(title: string) {
    // Change words color for magenta if it is surrounded by '*'
    title = title.replace(
        /\*([^*]+)\*/g,
        '<span style="color:#ee2b7b;">$1</span>',
    );

    // Replace words surrounded by '[]' with red color tags
    title = title.replace(/\[([^\]]+)\]/g, '<span style="text-transform:lowercase;font-family:Baskerville SemiBold italic version;">$1</span>');

    // Replace /n with a line break
    title = title.replace(/\/n/g, '<br/>');

    return title;
}
