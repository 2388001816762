import React from 'react';

export default function PrevArrowButton(props: any) {
   const { className, style, onClick } = props;
   return (
       <button
           className={className}
           style={{
               ...style,
               display: 'block',
               background: '#fce3ed',
               color: '#EE2B7B',
               width: '48px',
               height: '48px',
               borderRadius: '50%',
           }}
           onClick={onClick}
       >
           <svg
               width="28"
               height="28"
               viewBox="0 0 28 28"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
           >
               <path
                   d="M6.87796 13.0129L23.742 13.0129C23.8771 13.0087 24.0116 13.0321 24.1373 13.0818C24.2631 13.1315 24.3773 13.2065 24.473 13.3019C24.5684 13.3976 24.6433 13.5119 24.693 13.6376C24.7427 13.7633 24.7662 13.8978 24.762 14.033C24.7662 14.1681 24.7427 14.3026 24.693 14.4283C24.6433 14.5541 24.5684 14.6682 24.473 14.7639C24.3773 14.8594 24.2631 14.9343 24.1373 14.984C24.0116 15.0337 23.8771 15.0571 23.742 15.0529L6.87796 15.0529L14.596 22.7709C14.693 22.8628 14.7702 22.9735 14.8228 23.0963C14.8754 23.2191 14.9024 23.3513 14.902 23.4849C14.9024 23.6185 14.8754 23.7508 14.8228 23.8736C14.7702 23.9964 14.693 24.1071 14.596 24.1989C14.5042 24.296 14.3934 24.3733 14.2706 24.4259C14.1478 24.4785 14.0156 24.5054 13.882 24.5049C13.7484 24.5053 13.6161 24.4784 13.4933 24.4258C13.3705 24.3732 13.2598 24.2959 13.168 24.1989L3.71596 14.7469C3.61448 14.6508 3.53353 14.5352 3.47797 14.4069C3.4316 14.2877 3.4085 14.1608 3.40996 14.033C3.4085 13.9051 3.4316 13.7781 3.47797 13.6589C3.53356 13.5307 3.61451 13.4151 3.71596 13.319L13.168 3.86694C13.2598 3.76991 13.3705 3.6927 13.4933 3.64007C13.6161 3.58744 13.7484 3.56056 13.882 3.56097C14.0156 3.56056 14.1478 3.58744 14.2706 3.64007C14.3934 3.6927 14.5041 3.76991 14.596 3.86694C14.693 3.95874 14.7703 4.06945 14.8229 4.19226C14.8755 4.31506 14.9024 4.44732 14.902 4.58093C14.9024 4.71452 14.8754 4.84681 14.8228 4.9696C14.7702 5.09239 14.693 5.20308 14.596 5.29492L6.87796 13.0129Z"
                   fill="#EE2B7B"
               />
           </svg>
       </button>
   );
}
