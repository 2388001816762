import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Fade,
    Grow,
    Typography,
} from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './carrusel2.module.scss';
import './carrusel2.scss';
import NextArrowButton from '../../atoms/next-arrow-button/nextArrowButton';
import PrevArrowButton from '../../atoms/prev-arrow-button/prevArrowButton';
import useScreenSize from '../../../hooks/useScreenSize';

export interface ICarrusel2Props {
    title?: string;
    description?: any;
    slidesToShow?: number;
    slidesToScroll?: number;
    bgImage?: string;
    bgImage2?: string;
    cardsList: [
        {
            image: string;
            title: string;
            description: string;
            bgColor: string;
            textColor: string;
            redirect: string;
            btnTitle: string;
        },
    ];
}

const Carrusel2 = ({
    title,
    description,
    cardsList,
    slidesToShow,
    slidesToScroll,
    bgImage,
    bgImage2,
}: ICarrusel2Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const screenSize = useScreenSize();

    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: slidesToShow ? slidesToShow : 3,
        slidesToScroll: slidesToScroll ? slidesToScroll : 3,
        autoplay: false,
        nextArrow: <NextArrowButton />,
        prevArrow: <PrevArrowButton />,
        responsive: [
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const elemRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (elemRef.current) {
            observer.observe(elemRef.current);
        }
        return () => observer.disconnect();
    }, []);

    const elemRef2 = useRef(null);
    useEffect(() => {
        const observer2 = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible2(entries[0].isIntersecting);
            }
        });
        if (elemRef2.current) {
            observer2.observe(elemRef2.current);
        }
        return () => observer2.disconnect();
    }, []);

    return (
        <div className={styles.o_carrusel2__main}>
            <Container
                component="section"
                className={`${styles.o_carrusel2} o_carrusel2`}
            >
                {bgImage && (
                    <img
                        src={bgImage}
                        alt=""
                        className={styles.o_carrusel2__bg}
                    />
                )}
                {bgImage2 && screenSize > 968 && (
                    <img
                        src={bgImage2}
                        alt=""
                        className={styles.o_carrusel2__bg2}
                    />
                )}
                <Box className={styles.o_carrusel2__intro}>
                    <Grow in={isVisible} timeout={1000}>
                        <div ref={elemRef}>
                            {title && (
                                <Typography
                                    className={styles.o_carrusel2__h2}
                                    variant="h2"
                                >
                                    {title}
                                </Typography>
                            )}
                            {description && (
                                <Typography
                                    className={styles.o_carrusel2__body}
                                    variant="body1"
                                >
                                    {description}
                                </Typography>
                            )}
                        </div>
                    </Grow>
                </Box>
                <Fade in={isVisible2} timeout={2000}>
                    <div ref={elemRef2}>
                        <Slider {...settings} className="o_carrusel2__slider">
                            {cardsList.map((card, key) => (
                                <Card key={key} className="o_carrusel2__slide">
                                    <div
                                        className="o_carrusel2__slideContainer"
                                        style={{
                                            color: card.textColor
                                                ? card.textColor
                                                : 'black',
                                            backgroundColor: card.bgColor,
                                        }}
                                    >
                                        <img
                                            loading="eager"
                                            src={card.image}
                                            alt={card.title}
                                            className="o_carrusel2__img"
                                        />
                                        <CardContent className="o_carrusel2__info">
                                            <Typography
                                                variant="h3"
                                                component="div"
                                                className="o_carrusel2__h3"
                                            >
                                                {card.title}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                component="p"
                                                className="o_carrusel2__desc"
                                            >
                                                {card.description}
                                            </Typography>
                                            {card.redirect && (
                                                <CardActions className="o_carrusel2__actions">
                                                    <Button
                                                        component="a"
                                                        size="small"
                                                        href={card.redirect}
                                                        className="o_carrusel2__link"
                                                    >
                                                        {card.btnTitle}
                                                    </Button>
                                                </CardActions>
                                            )}
                                        </CardContent>
                                    </div>
                                </Card>
                            ))}
                        </Slider>
                    </div>
                </Fade>
            </Container>
        </div>
    );
};

export default Carrusel2;
