import React, { useState, useEffect, Fragment } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from '../../atoms/button/button';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ReferenciaPlantillaContenido } from '../../../interfaces/ReferenciaPlantillaContenido';
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido';
import {
    normalizeTwoWords,
    OptimizeImage,
} from '../../../shared/utils/stringUtilities';
import Img from 'gatsby-image';
import './contentRotator.scss';

const ContentRotator = (props: ReferenciaContenido) => {
    const { nombreCategoria } = props;
    const [nodesShuffle, setNodesShuffle] = useState<
        ReferenciaPlantillaContenido[]
    >([]);

    useEffect(() => {
        setNodesShuffle(shuffleArray(props.referenciaPlantillaContenido));
    }, []);

    const [sliderIndex, setSliderIndex] = useState(0);
    const [slider, setSlider] = useState<any>();

    const goToNext = () => {
        slider.slickGoTo(sliderIndex + 1);
    };

    const goToBack = () => {
        slider.slickGoTo(sliderIndex - 1);
    };

    const isActiveBack = (_true: any, _false: any) =>
        sliderIndex > 0 ? _true : _false;
    const isActiveNext = (_true: any, _false: any) =>
        sliderIndex < props.referenciaPlantillaContenido.length - 1
            ? _true
            : _false;

    const settings = {
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (_: number, next: number) => setSliderIndex(next),
    };

    function shuffleArray(array: ReferenciaPlantillaContenido[]) {
        let i = array.length - 1;
        for (; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    return (
        <div className="o-content-rotator">
            <div className="o-content-rotator__background"></div>
            <Slider
                ref={(_slider) => setSlider(_slider)}
                {...settings}
                className="o-content-rotator__carrusel"
            >
                {nodesShuffle.map((value, index) => (
                    <Fragment key={index}>
                        <div className="o-content-rotator__carrusel__content">
                            {value.imagenPrincipal &&
                            value.imagenPrincipal[0].fluid ? (
                                <Img
                                    className="o-content-rotator__carrusel__content__cover"
                                    fluid={value.imagenPrincipal[0].fluid}
                                    alt={
                                        value.imagenPrincipal[0].title ||
                                        'imagen rotador'
                                    }
                                />
                            ) : (
                                <img
                                    className="o-content-rotator__carrusel__content__cover"
                                    src={OptimizeImage(
                                        value.imagenPrincipal[0].file.url,
                                        value.imagenPrincipal[0].file
                                            .contentType,
                                        768,
                                    )}
                                    alt={
                                        value.imagenPrincipal[0].title ||
                                        'imagen rotador'
                                    }
                                    loading="lazy"
                                />
                            )}
                            <div className="o-content-rotator__carrusel__background">
                                <article className="o-content-rotator__carrusel__content__text">
                                    <h3 className="o-content-rotator__carrusel__content__text__title">
                                        {value.tituloBanner}
                                    </h3>
                                    <div className="o-content-rotator__carrusel__content__text__description">
                                        {value.descripcion.json.content &&
                                            documentToReactComponents(
                                                value.descripcion.json,
                                            )}
                                    </div>
                                    <div className="o-content-rotator__carrusel__content__text__action">
                                        <Button
                                            id={'buttonContentRotator-' + index}
                                            type="link"
                                            href={value.redireccionBoton}
                                            classname={
                                                'primary ' +
                                                value.imagenPrincipal[0].title
                                            }
                                            text={value.tituloBoton}
                                            isDisabled={false}
                                            gtmMark={`gtm${normalizeTwoWords(
                                                nombreCategoria,
                                            )}ComponenteRotadorConoceMas${normalizeTwoWords(
                                                value.tituloBanner,
                                            )}`}
                                        />
                                    </div>
                                </article>
                            </div>
                        </div>
                    </Fragment>
                ))}
            </Slider>

            <div
                className="arrow-content arrow-content__left"
                onClick={isActiveBack(goToBack, undefined)}
            >
                <div className="arrows">
                    <div className="arrows-contain">
                        <div
                            className={isActiveBack(
                                'arrow icon-chevron-left active',
                                'arrow icon-chevron-left',
                            )}
                        />
                    </div>
                </div>
            </div>

            <div
                className="arrow-content arrow-content__right"
                onClick={isActiveNext(goToNext, undefined)}
            >
                <div className="arrows">
                    <div className="arrows-contain">
                        <div
                            className={isActiveNext(
                                'arrow icon-chevron-right active',
                                'arrow icon-chevron-right',
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentRotator;
